window.utils = {
    cookies: {
        set: function(cname, cvalue, exdays = 100){
            let d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            let expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";SameSite=Strict;path=/"; 
        },
        get: function(cname){
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
            }
            return "";
        },
        del: function(cname){
            document.cookie = cname + "=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
        }
    }
};