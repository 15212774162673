/**
 * Require all svg files
 */

/**
 * Require context files
 *
 * @param r
 */
 function requireAll(r) {
    r.keys().forEach(r);
}

requireAll(require.context('./', true, /\.svg$/));